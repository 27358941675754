.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.subheader-container {
  display: flex;
}

.subheader-title {
  color: #5a607f;
  font-size: 14px;
}

.pagination-container {
  margin: 15px 0;
}

.pagination {
  font-size: 12px;
  color: #001737;
}

.export-button {
  cursor: pointer;
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;

  .export-button-icon {
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1280px) {

  .subheader-title,
  .export-button {
    font-size: 14px;
  }

  .pagination {
    font-size: 12px;
  }
}

@media screen and (min-width: 1366px) {

  .subheader-title,
  .export-button {
    font-size: 15.75px;
  }

  .pagination {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 1920px) {

  .subheader-title,
  .export-button {
    font-size: 17.71px;
  }

  .pagination {
    font-size: 15.18px;
  }
}