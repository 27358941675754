.ant-color-picker .ant-color-picker-panel .ant-color-picker-input-container .ant-color-picker-format-select.ant-select-single .ant-select-selection-item {
    color: white;
}

.ant-color-picker .ant-color-picker-panel .ant-color-picker-input-container .ant-color-picker-format-select.ant-select-single .ant-select-arrow {
    color: white;
}

.ant-color-picker-trigger {
    margin-top: 0.5em;
    width: auto;
    height: 38px;
}

.ant-color-picker-trigger .ant-color-picker-color-block {
    width: 97%;
}