.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.flex {
  flex: 1;
}

.left-content {
  height: 100%;
  display: flex;
  align-items: center;
}

.help-text {
  color: #001737;
  font-size: 12px;
}

.middle-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 30%;
  width: 385px;
}

.search-container {
  width: 385px;
  max-height: 385px;
  z-index: 1;
  top: 50%;
  position: absolute;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 26px 15px;
  overflow: scroll;
  overflow-x: hidden;
}

.search-title {
  color: #2b80ff;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 10px;
}

.search-text {
  color: #7f7f7f;
  font-size: 11px;
}

.right-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.header-avatar {
  width: 45px;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}

.user-name {
  width: 140px;
  display: inline-block;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.actions-menu-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  font-size: 12px;
  cursor: pointer;
}

.actions-menu-button:hover {
  opacity: 0.7;
}

.actions-box {
  width: 165px;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  background: white;
  position: absolute;
  bottom: -112px;
  z-index: 1000;
  flex-wrap: wrap;
  border: solid #d8d6d6 0.01px;
}

.logout {
  font-size: 12px;
  cursor: pointer;
}

.logout:hover {
  opacity: 0.7;
}

.logout-title {
  width: 100%;
  color: #868e96;
  font-size: 13px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.logout-text {
  width: 100%;
  font-size: 12px;
  padding-left: 10px;
  cursor: pointer;
}

.logout-text:hover {
  opacity: 0.7;
}

.tag-container {
  width: 126.86px;
  height: 25px;
  border-radius: 5px;
  background: #f2a900;
  position: absolute;
  left: -134.48px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-text {
  color: white;
  font-size: 12px;
}

.tag-arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  right: -10px;
  top: 6px;
  background: white;
  border-left: 7px solid #f2a900;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

@media screen and (min-width: 1280px) {

  .help-text,
  .search-title,
  .actions-menu-button,
  .logout,
  .logout-text,
  .tag-text {
    font-size: 12px;
  }

  .search-container {
    width: 433.12px;
    max-height: 433.12px;
  }

  .search-text {
    font-size: 11px;
  }

  .header-avatar {
    width: 45px;
    height: 35px;
    line-height: 35px;
  }

  .actions-box {
    width: 165px;
    bottom: -112px;
  }

  .logout-title {
    font-size: 13px;
  }

  .tag-container {
    width: 126.86px;
    height: 25px;
    left: -134.48px;
    top: 20px;
  }

  .tag-arrow {
    top: 6px;
  }
}

@media screen and (min-width: 1366px) {

  .help-text,
  .search-title,
  .actions-menu-button,
  .logout,
  .logout-text,
  .tag-text {
    font-size: 13.5px;
  }

  .search-container {
    width: 487.26px;
    max-height: 487.26px;
  }

  .search-text {
    font-size: 12.37px;
  }

  .header-avatar {
    width: 49.37px;
    height: 39.37px;
    line-height: 39.37px;
  }

  .actions-box {
    width: 185.62px;
    bottom: -118px;
  }

  .logout-title {
    font-size: 14.62px;
  }

  .tag-container {
    width: 142.72px;
    height: 28.12px;
    left: -151.29px;
    top: 22.5px;
  }

  .tag-arrow {
    top: 7px;
  }
}

@media screen and (min-width: 1920px) {

  .help-text,
  .search-title,
  .actions-menu-button,
  .logout,
  .logout-text,
  .tag-text {
    font-size: 15.18px;
  }

  .search-container {
    width: 548.16px;
    max-height: 548.16px;
  }

  .search-text {
    font-size: 13.91px;
  }

  .header-avatar {
    width: 54.29px;
    height: 44.29px;
    line-height: 44.29px;
  }

  .actions-box {
    width: 208.82px;
    bottom: -126px;
  }

  .logout-title {
    font-size: 16.44px;
  }

  .tag-container {
    width: 160.56px;
    height: 31.63px;
    left: -170.21px;
    top: 25.31px;
  }

  .tag-arrow {
    top: 8px;
  }
}