.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: white;
  padding: 5.6% 5.5% 5.6% 5.2%;
  overflow-y: scroll;
}

.form-title {
  color: #5a607f;
  font-size: 20px;
  font-weight: bold;
}

.close-form-icon {
  font-size: 14px;
  color: #959595;
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.blue {
  color: #426da9;
}

.form-input {
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.form-textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-textarea:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.upload-image-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: #fafafa;
  padding: 1em;
  cursor: pointer;
}

.upload-image-box:hover {
  border: 1px dashed #426da9;
}

.upload-image-icon {
  color: #dde4eb;
  font-size: 25px;
  margin-bottom: 10px;
}

.logo-text {
  width: 50%;
  text-align: center;
  font-size: 10px;
  color: #5a607f;
}

.logo-required {
  font-size: 9px;
  color: #426da9;
}

.temporal-image {
  width: 100%;
  height: 150px;
  border-radius: 3px;
  object-fit: contain;
  border: 1px solid silver;
  background: #bababa;
}

.clear-temporal-image-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: red;
  border-radius: 2px;
}

.clear-temporal-image-icon:hover {
  background: #f0f0f0;
  color: grey;
}

.form-action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  font-size: 12px;
}

.form-action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.form-action-button-save:hover {
  background: #426da9;
  color: white !important;
  border: none;
}

.form-action-button-cancel {
  border: 1px solid #bfccda;
}

.form-action-button-cancel:hover {
  background: #dde4eb;
  border: none;
  color: black !important;
}

@media screen and (min-width: 1280px) {
  .form-title {
    font-size: 20px;
  }

  .close-form-icon,
  .form-section-title {
    font-size: 14px;
  }

  .form-input-name,
  .form-input,
  .form-textarea,
  .error-message,
  .form-action-button {
    font-size: 12px;
  }

  .upload-image-box,
  .temporal-image {
    height: 150px;
  }

  .upload-image-icon {
    font-size: 25px;
  }

  .logo-text {
    font-size: 10px;
  }

  .logo-required {
    font-size: 9px;
  }

  .clear-temporal-image-icon {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }

  .form-action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {
  .form-title {
    font-size: 22.5px;
  }

  .close-form-icon,
  .form-section-title {
    font-size: 15.75px;
  }

  .form-input-name,
  .form-input,
  .form-textarea,
  .error-message,
  .form-action-button {
    font-size: 13.5px;
  }

  .upload-image-box,
  .temporal-image {
    height: 168.75px;
  }

  .upload-image-icon {
    font-size: 28.12px;
  }

  .logo-text {
    font-size: 11.25px;
  }

  .logo-required {
    font-size: 10.12px;
  }

  .clear-temporal-image-icon {
    width: 28.12px;
    height: 28.12px;
    font-size: 18px;
  }

  .form-action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1920px) {
  .form-title {
    font-size: 25.31px;
  }

  .close-form-icon,
  .form-section-title {
    font-size: 17.71px;
  }

  .form-input-name,
  .form-input,
  .form-textarea,
  .error-message,
  .form-action-button {
    font-size: 15.18px;
  }

  .upload-image-box,
  .temporal-image {
    height: 189.84px;
  }

  .upload-image-icon {
    font-size: 31.63px;
  }

  .logo-text {
    font-size: 12.65px;
  }

  .logo-required {
    font-size: 11.38px;
  }

  .clear-temporal-image-icon {
    width: 31.63px;
    height: 31.63px;
    font-size: 20.25px;
  }

  .form-action-button {
    width: 106.31px;
    height: 43.03px;
  }
}