.row {
  display: flex;
}

.history-title {
  color: #2b80ff;
  font-size: 14px;
  font-weight: bold;
}

.history-paragraph {
  font-size: 14px;
  font-weight: 400;
  color: #5a607f;
}

@media screen and (min-width: 1280px) {

  .history-title,
  .history-paragraph {
    font-size: 14px;
  }
}

@media screen and (min-width: 1366px) {

  .history-title,
  .history-paragraph {
    font-size: 15.75px;
  }
}

@media screen and (min-width: 1920px) {

  .history-title,
  .history-paragraph {
    font-size: 17.71px;
  }
}