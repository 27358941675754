.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.blue {
  color: #426da9
}

.section-container {
  background-color: white;
  display: flex;
  flex-direction: column;
}

.button-modify {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 34px;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 400;
}

.button-verify {
  width: 146px;
  height: 34px;
  border: 1px solid #bfccda;
  border-radius: 3px;
  font-size: 12px;
  color: #131523;
}

.button-verify:hover {
  border: 1px solid #426da9;
}

.verified-icon {
  margin-right: 10px;
  color: #3cdb7f;
  font-size: 13px;
}

.verified-text {
  color: #2b80ff;
  font-size: 14px;
  font-weight: bold;
}

.column-left {
  width: 60%;
  background-color: #f6f6f6;
  border: 1px solid #dde4eb;
  padding: 25px 25px 60px 25px;
  margin-top: 30px;
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.form-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.form-textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-textarea:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.logo-container {
  border: solid 1px #d9d9d9;
  padding: 1em;
  border-radius: 5px;
}

.logo-image {
  object-fit: contain;
  background-color: #bababa;
  border-radius: 5px;
  margin-right: 2em;
  width: 105.5px;
  height: 69.5px;
}

.logo-name {
  flex: 1;
  font-size: 12px;
  color: #5a607f;
}

.hidden-button {
  border: none;
  outline: none;
  background-color: #f6f6f6;
}

.hidden-button:hover {
  opacity: 0.8;
}

.delete-logo-icon {
  font-size: 25px;
  color: #426da9;
}

.upload-image-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: #fafafa;
  padding: 1em;
  cursor: pointer;
}

.upload-image-box:hover {
  border: 1px dashed #426da9;
}

.upload-image-icon {
  color: #dde4eb;
  font-size: 25px;
  margin-bottom: 10px;
}

.logo-title {
  color: #2b80ff;
  font-size: 12px;
}

.logo-text {
  width: 50%;
  text-align: center;
  font-size: 10px;
  color: #5a607f;
}

.logo-required-text {
  color: #426da9;
  font-size: 9px;
}

.temporal-image {
  width: 100%;
  height: 150px;
  border-radius: 3px;
  object-fit: contain;
  border: 1px solid silver;
  background: #bababa;
}

.clear-temporal-image-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: red;
  border-radius: 2px;
}

.clear-temporal-image-icon:hover {
  background: #f0f0f0;
  color: grey;
}

.column-right {
  padding: 25px 20px 90px 25px;
  margin-top: 30px;
}

.switch-text {
  font-size: 12px;
  color: #131523;
  align-self: center;
}

.important-info-title {
  font-size: 12px;
  color: #2b80ff;
  font-weight: bold;
}

.important-info-description {
  font-size: 10px;
  color: #959595;
}

.contact-form-button {
  padding: 0;
  color: #2b80ff;
  font-size: 12px;
  font-weight: bold;
}

.add-contact-button {
  width: 100%;
  height: 34px;
  background-color: #1d428a;
  color: #ffffff;
  border-radius: 3px;
  font-size: 12px;
}

.add-contact-button:hover {
  color: #ffffff !important;
  opacity: 0.8;
}

.contacts-container {
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 20px 0 20px 20px;
}

.contact-name {
  color: #2b80ff;
  font-size: 15px;
  font-weight: bold;
}

.contact-details {
  color: #5a607f;
  font-size: 12px;
}

.charge-tag {
  background-color: #2b80ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  padding: 0 10px;
  width: 100px;
  height: 30px;
}

.charge-details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  font-weight: 900;
  font-size: 12px;
}

.delete-contact-container {
  background-color: #0e3175;
  padding: 0 8px;
  border-radius: 3px;
  margin: 10px 30px 0 0;
  cursor: pointer;
}

.delete-contact-icon {
  font-size: 12px;
  color: #ffffff;
}

.action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  color: #001737;
  font-size: 12px;
}

.action-button:hover {
  color: #426da9;
  border: none;
}

.action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.action-button-cancel {
  border: 1px solid #bfccda;
}

.authentication-backdrop {
  background: hsla(0, 0%, 0%, 0.5);
  width: 42%;
  height: 100%;
}

@media screen and (min-width: 1280px) {

  .button-modify,
  .button-verify,
  .form-input-name,
  .form-input,
  .form-textarea,
  .error-message,
  .logo-name,
  .logo-title,
  .switch-text,
  .important-info-title,
  .contact-form-button,
  .contact-details,
  .charge-details,
  .delete-contact-icon,
  .action-button {
    font-size: 12px;
  }

  .button-modify {
    width: 101px;
    height: 34px;
  }

  .button-verify {
    width: 146px;
    height: 34px;
  }

  .verified-icon {
    font-size: 13px;
  }

  .verified-text,
  .form-section-title {
    font-size: 14px;
  }

  .logo-image {
    width: 105.5px;
    height: 69.5px;
  }

  .delete-logo-icon {
    font-size: 25px;
  }

  .upload-image-box,
  .temporal-image {
    height: 150px;
  }

  .upload-image-icon {
    font-size: 25px;
  }

  .logo-text,
  .important-info-description {
    font-size: 10px;
  }

  .logo-required-text {
    font-size: 9px;
  }

  .clear-temporal-image-icon {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }

  .add-contact-button {
    height: 34px;
    font-size: 12px;
  }

  .contact-name {
    font-size: 15px;
  }

  .charge-tag {
    width: 100px;
    height: 30px;
  }

  .action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {

  .button-modify,
  .button-verify,
  .form-input-name,
  .form-input,
  .form-textarea,
  .error-message,
  .logo-name,
  .logo-title,
  .switch-text,
  .important-info-title,
  .contact-form-button,
  .contact-details,
  .charge-details,
  .delete-contact-icon,
  .action-button {
    font-size: 13.5px;
  }

  .button-modify {
    width: 113.62px;
    height: 38.25px;
  }

  .button-verify {
    width: 164.25px;
    height: 38.25px;
  }

  .verified-icon {
    font-size: 14.62px;
  }

  .verified-text,
  .form-section-title {
    font-size: 15.75px;
  }

  .logo-image {
    width: 118.68px;
    height: 78.18px;
  }

  .delete-logo-icon {
    font-size: 28.12px;
  }

  .upload-image-box,
  .temporal-image {
    height: 168.75px;
  }

  .upload-image-icon {
    font-size: 28.12px;
  }

  .logo-text,
  .important-info-description {
    font-size: 11.25px;
  }

  .logo-required-text {
    font-size: 10.12px;
  }

  .clear-temporal-image-icon {
    width: 28.12px;
    height: 28.12px;
    font-size: 18px;
  }

  .add-contact-button {
    height: 38.25px;
    font-size: 13.5px;
  }

  .contact-name {
    font-size: 16.87px;
  }

  .charge-tag {
    width: 112.5px;
    height: 33.75px;
  }

  .action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1920px) {

  .button-modify,
  .button-verify,
  .form-input-name,
  .form-input,
  .form-textarea,
  .error-message,
  .logo-name,
  .logo-title,
  .switch-text,
  .important-info-title,
  .contact-form-button,
  .contact-details,
  .charge-details,
  .delete-contact-icon,
  .action-button {
    font-size: 15.18px;
  }

  .button-modify {
    width: 127.82px;
    height: 43.03px;
  }

  .button-verify {
    width: 184.78px;
    height: 43.03px;
  }

  .verified-icon {
    font-size: 16.44px;
  }

  .verified-text,
  .form-section-title {
    font-size: 17.71px;
  }

  .logo-image {
    width: 133.51px;
    height: 87.95px;
  }

  .delete-logo-icon {
    font-size: 31.63px;
  }

  .upload-image-box,
  .temporal-image {
    height: 189.84px;
  }

  .upload-image-icon {
    font-size: 31.63px;
  }

  .logo-text,
  .important-info-description {
    font-size: 12.65px;
  }

  .logo-required-text {
    font-size: 11.38px;
  }

  .clear-temporal-image-icon {
    width: 31.63px;
    height: 31.63px;
    font-size: 20.25px;
  }

  .add-contact-button {
    height: 34.03px;
    font-size: 15.18px;
  }

  .contact-name {
    font-size: 18.97px;
  }

  .charge-tag {
    width: 126.56px;
    height: 37.96px;
  }

  .action-button {
    width: 106.31px;
    height: 43.03px;
  }
}