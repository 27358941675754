.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.blue {
  color: #426da9
}

.section-container {
  background-color: white;
  display: flex;
  flex-direction: column;
}

.button-modify {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 34px;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 400;
}

.column-left {
  padding: 25px 50px 90px 25px;
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.installation-date {
  font-size: 11px;
  color: #959595;
}

.sale-status {
  border-radius: 2px;
  background-color: #e9f4ff;
  padding: 10px 0px;
}

.sale-status-text {
  font-size: 22px;
  font-weight: bold;
  color: #131523;
}

.sale-info-title {
  font-size: 12px;
  font-weight: bold;
  color: #172b4d;
}

.sale-info-text {
  font-size: 14px;
  color: #959595;
}

.sale-address-title {
  font-size: 12px;
  font-weight: bold;
  color: #959595;
}

.sale-address {
  font-size: 14px;
  color: #172b4d;
}

.characteristics-icon {
  color: #bcbcbc;
}

.internet-characteristics {
  color: #bcbcbc;
  font-size: 14px;
  margin: 0 5px 0 5px;
}

.sd-channels {
  color: #bcbcbc;
  font-size: 14px;
}

.hd-channels {
  color: #bcbcbc;
  font-size: 14px;
  margin-left: 10px;
}

.telephone {
  color: #bcbcbc;
  font-size: 14px;
}

.column-right {
  width: 50%;
  background-color: #f6f6f6;
  border: 1px solid #dde4eb;
  padding: 25px 25px 60px 25px;
  margin-top: 30px;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.form-input {
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.switch-text {
  font-size: 12px;
  color: #131523;
}

.switch-margin {
  margin: 0 15px 0;
}

.upload-image-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: #fafafa;
  padding: 1em;
  cursor: pointer;
}

.upload-image-box:hover {
  border: 1px dashed #426da9;
}

.upload-image-icon {
  color: #dde4eb;
  font-size: 25px;
  margin-bottom: 10px;
}

.image-title {
  color: #2b80ff;
  font-size: 12px;
}

.image-text {
  width: 50%;
  text-align: center;
  font-size: 10px;
  color: #5a607f;
}

.image-required-text {
  color: #426da9;
  font-size: 9px;
}

.temporal-image {
  width: 100%;
  height: 150px;
  border-radius: 3px;
  object-fit: contain;
  border: 1px solid silver;
  background: #bababa;
}

.clear-temporal-image-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: red;
  border-radius: 2px;
}

.clear-temporal-image-icon:hover {
  background: #f0f0f0;
  color: grey;
}

.file-container {
  background-color: #ffffff;
  border: solid 1px #d9d9d9;
  padding: 1em;
  border-radius: 5px;
}

.file-image {
  object-fit: contain;
  background-color: #bababa;
  border-radius: 5px;
  margin-right: 2em;
  width: 105.5px;
  height: 69.5px;
}

.file-name {
  font-size: 12px;
  color: #5a607f;
}

.tag-container {
  width: 140px;
  height: 25px;
  background-color: #007bff;
  border-radius: 3px;
}

.tag-text {
  font-size: 10px;
  color: #ffffff;
  font-weight: 900;
}

.action-buttons-margin {
  margin: 35px 0 0 0;
}

.action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  color: #001737;
  font-size: 12px;
}

.action-button:hover {
  color: #426da9;
  border: none;
}

.action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.action-button-cancel {
  border: 1px solid #bfccda;
}

@media screen and (min-width: 1280px) {

  .button-modify,
  .sale-info-title,
  .sale-address-title,
  .form-input-name,
  .form-input,
  .error-message,
  .switch-text,
  .image-title,
  .file-name,
  .action-button {
    font-size: 12px;
  }

  .button-modify {
    width: 101px;
    height: 34px;
  }

  .form-section-title,
  .sale-info-text,
  .sale-address,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone {
    font-size: 14px;
  }

  .installation-date {
    font-size: 11px;
  }

  .sale-status-text {
    font-size: 22px;
  }

  .upload-image-box,
  .temporal-image {
    height: 150px;
  }

  .upload-image-icon {
    font-size: 25px;
  }

  .image-text,
  .tag-text {
    font-size: 10px;
  }

  .image-required-text {
    font-size: 9px;
  }

  .clear-temporal-image-icon {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }

  .file-image {
    width: 105.5px;
    height: 69.5px;
  }

  .tag-container {
    width: 140px;
    height: 25px;
  }

  .action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1280px) {

  .button-modify,
  .sale-info-title,
  .sale-address-title,
  .form-input-name,
  .form-input,
  .error-message,
  .switch-text,
  .image-title,
  .file-name,
  .action-button {
    font-size: 13.5px;
  }

  .button-modify {
    width: 113.62px;
    height: 38.25px;
  }

  .form-section-title,
  .sale-info-text,
  .sale-address,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone {
    font-size: 15.75px;
  }

  .installation-date {
    font-size: 12.37px;
  }

  .sale-status-text {
    font-size: 24.75px;
  }

  .upload-image-box,
  .temporal-image {
    height: 168.75px;
  }

  .upload-image-icon {
    font-size: 28.12px;
  }

  .image-text,
  .tag-text {
    font-size: 11.25px;
  }

  .image-required-text {
    font-size: 10.12px;
  }

  .clear-temporal-image-icon {
    width: 28.12px;
    height: 28.12px;
    font-size: 18px;
  }

  .file-image {
    width: 118.68px;
    height: 78.18px;
  }

  .tag-container {
    width: 157.5px;
    height: 28.12px;
  }

  .action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1280px) {

  .button-modify,
  .sale-info-title,
  .sale-address-title,
  .form-input-name,
  .form-input,
  .error-message,
  .switch-text,
  .image-title,
  .file-name,
  .action-button {
    font-size: 15.18px;
  }

  .button-modify {
    width: 127.82px;
    height: 43.03px;
  }

  .form-section-title,
  .sale-info-text,
  .sale-address,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone {
    font-size: 17.71px;
  }

  .installation-date {
    font-size: 13.91px;
  }

  .sale-status-text {
    font-size: 27.84px;
  }

  .upload-image-box,
  .temporal-image {
    height: 189.84px;
  }

  .upload-image-icon {
    font-size: 31.63px;
  }

  .image-text,
  .tag-text {
    font-size: 12.65px;
  }

  .image-required-text {
    font-size: 11.38px;
  }

  .clear-temporal-image-icon {
    width: 31.63px;
    height: 31.63px;
    font-size: 20.25px;
  }

  .file-image {
    width: 133.51px;
    height: 87.95px;
  }

  .tag-container {
    width: 177.18px;
    height: 31.63px;
  }

  .action-button {
    width: 106.31px;
    height: 43.03px;
  }
}