:root {
  --primary-color: #1e88e5;
  --secondary-color: #4fc3f7; }

#root {
  margin-bottom: 20px; }
  @media screen and (min-width: 460px) {
    #root {
      margin-bottom: 0; } }

.relative {
  position: relative; }

.header.navbar-default {
  background-color: var(--primary-color); }
  .header.navbar-default .navbar-brand img {
    margin-right: 0.5rem; }
  .header.navbar-default .navbar-nav li > a .label {
    background-color: var(--secondary-color); }

.ReactTable .rt-table .rt-thead.-header,
.ReactTable .rt-table .rt-thead .rt-th {
  border: none; }

.ReactTable .rt-table .rt-tbody .rt-td {
  border: none; }

.clickable {
  cursor: pointer; }

.full {
  width: 100%; }

.rdt_TableHeader {
  min-height: 0; }

.is_advanced .rdt_Table .rdt_TableRow:first-child .rdt_TableCell {
  background-color: #f7f7f7; }

.is_display_options .rdt_Table .rdt_TableRow:first-child .rdt_TableCell {
  padding-left: 2rem; }

.input-group {
  display: flex;
  padding: var(--margin);
  flex-flow: row nowrap;
  width: 100%;
  border-radius: 4px; }
  .input-group input[type="text"],
  .input-group input[type="number"] {
    background: none;
    outline: none;
    padding: 0.5rem;
    border: 1px solid #ddd;
    outline: none; }
  .input-group .btn {
    min-width: 40%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.input-control {
  border: 0;
  padding: 0.5rem;
  background: none;
  outline: none;
  padding: 0;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid #ddd; }
  .input-control--masked {
    padding: 0;
    border: 0; }

.nav-item a {
  cursor: pointer; }

.navbar-white {
  background-color: white; }

.new__sidebar .nav > li.active > a i {
  color: var(--secondary-color); }

.btn-primary {
  background-color: var(--primary-color);
  color: white; }

[class*="-primary"] {
  background-color: var(--primary-color);
  color: var(--primary-color); }

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none; }

.text-header {
  text-align: center;
  color: #ededed; }

.sidebar_background {
  background-image: linear-gradient(to top, #0f2441 0%, #1d4188 100%);
  margin: 0 !important;
  padding: 0 !important; }

.sidebar .nav > li.active__btn:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 5px;
  bottom: 10px;
  width: 5px;
  background: white;
  display: block; }

.active__li {
  background: white;
  color: white; }

.sidebar {
  position: inherit;
  background-image: linear-gradient(to top, #0f2441 0%, #1d4188 100%);
  height: 100%;
  min-width: 100%;
  font-family: "Lato", sans-serif; }

.sidebar .nav > li > a,
ul .d-block > li > a {
  padding: 7px 20px 14px;
  line-height: 25px;
  color: white;
  display: block;
  text-decoration: none;
  font-size: 15px; }
  .sidebar .nav > li > a :hover,
  ul .d-block > li > a :hover {
    color: rgba(255, 255, 255, 0.6); }

.sidebar .nav > li.expand.has-submenu:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 5px;
  bottom: 100px;
  width: 5px;
  background: white;
  display: block; }

.sidebar .nav > li .no__link__text {
  font-weight: bold;
  padding: 25px 20px 15px;
  line-height: 25px;
  display: block;
  color: white; }

.ul__row--center {
  display: flex;
  align-items: center; }

.ul__row--center .text {
  flex: 1; }

.sidebar .nav > li .no__hover {
  padding: 7px 20px 14px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  text-decoration: none;
  font-size: 15px; }

.ul__row--center .dots {
  cursor: pointer;
  color: white;
  line-height: 0; }
  .ul__row--center .dots :hover {
    color: rgba(255, 255, 255, 0.6); }

.ul__row--center .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 3px;
  margin-right: 9px; }

.ul__row--center .icon i {
  color: #0e3175; }

.ul__row--center .text {
  color: white;
  font-weight: bold;
  text-decoration: none; }

ul .d-block {
  padding-left: 8%;
  border-left: none !important;
  border: none !important;
  list-style-type: none;
  color: rgba(255, 255, 255, 0.6); }

@media screen and (min-width: 1280px) {
  .sidebar .nav > li > a,
  ul .d-block > li > a {
    font-size: 16px; }
  .sidebar .nav > li .no__link__text {
    font-size: 14px; }
  .ul__row--center .icon {
    width: 15px;
    height: 15px; }
  .ul__row--center .icon i {
    font-size: 10px; }
  .ul__row--center .text,
  .ul__row--center .dots i {
    font-size: 16px; } }

@media screen and (min-width: 1366px) {
  .sidebar .nav > li > a,
  ul .d-block > li > a {
    font-size: 18px; }
  .sidebar .nav > li .no__link__text {
    font-size: 15.75px; }
  .ul__row--center .icon {
    width: 16.87px;
    height: 16.87px; }
  .ul__row--center .icon i {
    font-size: 11.25px; }
  .ul__row--center .text,
  .ul__row--center .dots i {
    font-size: 18px; } }

@media screen and (min-width: 1920px) {
  .sidebar .nav > li > a,
  ul .d-block > li > a {
    font-size: 20.25px; }
  .sidebar .nav > li .no__link__text {
    font-size: 17.71px; }
  .ul__row--center .icon {
    width: 18.97px;
    height: 18.97px; }
  .ul__row--center .icon i {
    font-size: 12.65px; }
  .ul__row--center .text,
  .ul__row--center .dots i {
    font-size: 20.25px; } }

.grey__bg {
  background: #f3f3f3 !important;
  padding: 0 !important;
  padding-bottom: 12px !important;
  box-shadow: none !important; }

.panel__body {
  padding: 3.5em 5em 3.5em 2.5em;
  box-shadow: 0px 1px 4px #15223214;
  border: 1px solid #dde4eb; }

.panel__body__cons__coverage {
  margin-top: 10px;
  margin-bottom: -50px; }

.panel_no_margin {
  margin-bottom: 0 !important; }

.content {
  background: #f3f3f3 !important;
  box-sizing: border-box !important; }

.notification {
  width: 425px; }
  .notification .ant-notification-notice-message {
    font-size: 15px;
    color: #5a607f;
    font-weight: bold; }
  .notification .ant-notification-notice-description {
    font-size: 13px !important;
    color: #5a607f; }

.custom_scroll::-webkit-scrollbar {
  width: 10px;
  height: 6px; }

.custom_scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1); }

.custom_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2); }

.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4); }

.custom_scroll::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9); }
