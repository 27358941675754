.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: white;
  padding: 5.6% 5.5% 5.6% 5.2%;
  overflow-y: scroll;
}

.form-title {
  color: #5a607f;
  font-size: 20px;
  font-weight: bold;
}

.close-form-icon {
  font-size: 14px;
  color: #959595;
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
  margin-bottom: 7px;
}

.description-verify {
  font-size: 12px;
  margin-top: 3%;
  margin-bottom: 6%;
}

.image-verify {
  width: 144px;
  height: 131px;
  margin-bottom: 5%;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.blue {
  color: #426da9;
}

.form-input {
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.form-action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  font-size: 12px;
}

.form-action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.form-action-button-save:hover {
  background: #426da9;
  color: white !important;
  border: none;
}

.form-action-button-cancel {
  border: 1px solid #bfccda;
}

.form-action-button-cancel:hover {
  background: #dde4eb;
  border: none;
  color: black !important;
}

@media screen and (min-width: 1280px) {
  .form-title {
    font-size: 20px;
  }

  .close-form-icon,
  .form-section-title {
    font-size: 14px;
  }

  .description-verify,
  .form-input-name,
  .form-input,
  .form-action-button {
    font-size: 12px;
  }

  .image-verify {
    width: 144px;
    height: 131px;
  }

  .form-action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {
  .form-title {
    font-size: 22.5px;
  }

  .close-form-icon,
  .form-section-title {
    font-size: 15.75px;
  }

  .description-verify,
  .form-input-name,
  .form-input,
  .form-action-button {
    font-size: 13.5px;
  }

  .image-verify {
    width: 162px;
    height: 147.37px;
  }

  .form-action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1366px) {
  .form-title {
    font-size: 25.31px;
  }

  .close-form-icon,
  .form-section-title {
    font-size: 17.71px;
  }

  .description-verify,
  .form-input-name,
  .form-input,
  .form-action-button {
    font-size: 15.18px;
  }

  .image-verify {
    width: 182.25;
    height: 165.79px;
  }

  .form-action-button {
    width: 106.31px;
    height: 43.03px;
  }
}