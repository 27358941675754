.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.logo-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.system-logo {
  width: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}

.select-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.operator-box {
  width: 100%;
  height: 80px;
  background-color: #2f4d87;
  display: flex;
  align-items: center;
  justify-content: center;
}

.operator-select {
  width: 100%;
  border: none !important;
  outline: none !important;
  color: white;
}

.sidebar-avatar {
  width: 49px;
  height: 49px;
  line-height: 49px;
}

.operator-tag {
  font-size: 14px;
  color: white;
}

.operator-name {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.menu-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 31px 16px 21px;
}

.menu-title {
  color: #f1f1f1;
  padding-bottom: 5px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (min-width: 1280px) {
  .system-logo {
    width: 150px;
  }

  .operator-box {
    height: 80px;
  }

  .sidebar-avatar {
    width: 49px;
    height: 49px;
    line-height: 49px;
  }

  .operator-tag {
    font-size: 14px;
  }

  .operator-name,
  .menu-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 1366px) {
  .system-logo {
    width: 168.75px;
  }

  .operator-box {
    height: 90px;
  }

  .sidebar-avatar {
    width: 55.12px;
    height: 55.12px;
    line-height: 55.12px;
  }

  .operator-tag {
    font-size: 15.75px;
  }

  .operator-name,
  .menu-title {
    font-size: 20.25px;
  }
}

@media screen and (min-width: 1920px) {
  .system-logo {
    width: 189.84px;
  }

  .operator-box {
    height: 101.25px;
  }

  .sidebar-avatar {
    width: 62.01px;
    height: 62.01px;
    line-height: 62.01px;
  }

  .operator-tag {
    font-size: 17.71px;
  }

  .operator-name,
  .menu-title {
    font-size: 22.78px;
  }
}