.row {
  display: flex;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.header-title {
  color: #001737;
  font-size: 24px;
  font-weight: bold;
}

.header-breadcrumb {
  font-size: 12px;
  color: #001737;
}

.header-breadcrumb-text {
  font-weight: bold;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.header-breadcrumb-text:hover {
  cursor: pointer;
  color: #2b80ff;
  text-decoration-line: underline;
}

.created-date {
  font-size: 12px;
  color: #131523;
}

.selected-tab {
  border-radius: 3px !important;
  font-weight: 400 !important;
}

@media screen and (min-width: 1280px) {
  .header-title {
    font-size: 24px;
  }

  .created-date,
  .header-breadcrumb {
    font-size: 12px
  }
}

@media screen and (min-width: 1366px) {
  .header-title {
    font-size: 27px;
  }

  .created-date,
  .header-breadcrumb {
    font-size: 13.5px
  }
}

@media screen and (min-width: 1920px) {
  .header-title {
    font-size: 30.37px;
  }

  .created-date,
  .header-breadcrumb {
    font-size: 15.18px
  }
}