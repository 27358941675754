.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.container {
  background: hsla(0, 0%, 0%, 0.5);
  width: 42%;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: white;
  padding: 5.6% 5.5% 5.6% 5.2%;
  overflow-y: scroll;
}

.form-title {
  color: #5a607f;
  font-size: 20px;
  font-weight: bold;
}

.close-form-icon {
  font-size: 14px;
  color: #959595;
}

.form-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
}

.form-input-name {
  font-size: 12px;
  color: #131523;
}

.blue {
  color: #426da9;
}

.form-input {
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
  padding: 4px 11px 4px;
  margin-top: 0.5em;
  font-size: 12px;
}

.form-input:focus {
  border-color: #426da9;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
}

.error-message {
  position: absolute;
  color: red;
  font-size: 12px;
  margin: 3.5px 0 0 0;
}

.filter-container {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filter-container:hover {
  opacity: 0.8;
}

.filter-title {
  font-size: 12px;
  color: #868d96;
  margin-right: 10px;
}

.filter-dot {
  border-radius: 50%;
  background: #f2a900;
  height: 15px;
  width: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: white;
}

.filter-icon {
  font-size: 12px;
  color: #5a607f;
}

.filter-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  z-index: 100;
  position: absolute;
  top: 34%;
  background-color: white;
  padding: 15px 25px 30px;
}

.filter-options {
  font-size: 14px;
  color: #868e96;
}

.clean-filter-options {
  font-size: 12px;
  color: #2b80ff;
  cursor: pointer;
}

.clean-filter-options:hover {
  opacity: 0.8;
}

.filter-box-text {
  font-size: 12px;
  color: #131523;
}

.category-box {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  width: 77px;
  height: 62px;
  align-items: center;
  justify-content: center;
}

.category-box:hover {
  opacity: 0.7;
}

.selected-category-box {
  background-color: #315d9e;
  color: white;
}

.normal-category-box {
  border: 0.5px solid #152383;
  color: #152383;
}

.category-title {
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
}

.category-icon {
  font-size: 22px;
}

.filter-box-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
}

.select-all {
  font-size: 12px;
  color: #2b80ff;
  font-weight: bold;
}

.select-all:hover {
  opacity: 0.8;
}

.plan-characteristics-title {
  font-size: 11px;
  color: #959595;
  font-family: "Roboto", sans-serif;
}

.plan-name {
  color: #172b4d;
  font-size: 14px;
  font-weight: bold;
}

.plan-tech {
  color: #2b80ff;
  font-size: 12px;
  font-weight: 900;
}

.plan-description {
  font-size: 12px;
  color: #172b4d;
  font-weight: 300;
}

.characteristics-icon {
  color: #bcbcbc;
}

.internet-characteristics {
  color: #bcbcbc;
  font-size: 14px;
  margin: 0 5px 0 5px;
}

.sd-channels {
  color: #bcbcbc;
  font-size: 14px;
}

.hd-channels {
  color: #bcbcbc;
  font-size: 14px;
  margin-left: 10px;
}

.telephone {
  color: #bcbcbc;
  font-size: 14px;
}

.action-buttons-margin {
  margin: 35px 0 0 0;
}

.form-action-button {
  width: 84px;
  height: 34px;
  border-radius: 3px;
  font-size: 12px;
}

.form-action-button-save {
  border: 1px solid #426da9;
  background: #dde4eb;
}

.form-action-button-save:hover {
  background: #426da9;
  color: white !important;
  border: none;
}

.form-action-button-cancel {
  border: 1px solid #bfccda;
}

.form-action-button-cancel:hover {
  background: #dde4eb;
  border: none;
  color: black !important;
}

.authentication-modal-container {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1280px) {
  .form-title {
    font-size: 20px;
  }

  .close-form-icon,
  .form-section-title,
  .filter-options,
  .plan-name,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone {
    font-size: 14px;
  }

  .form-input-name,
  .filter-title,
  .filter-icon,
  .clean-filter-options,
  .filter-box-text,
  .category-title,
  .select-all,
  .form-input,
  .error-message,
  .plan-tech,
  .plan-description,
  .form-action-button {
    font-size: 12px;
  }

  .filter-dot {
    height: 15px;
    width: 15px;
    font-size: 10px;
  }

  .category-box {
    width: 77px;
    height: 62px;
  }

  .category-icon {
    font-size: 22px;
  }

  .plan-characteristics-title {
    font-size: 11px;
  }

  .form-action-button {
    width: 84px;
    height: 34px;
  }
}

@media screen and (min-width: 1366px) {
  .form-title {
    font-size: 22.5px;
  }

  .close-form-icon,
  .form-section-title,
  .filter-options,
  .plan-name,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone {
    font-size: 15.75px;
  }

  .form-input-name,
  .filter-title,
  .filter-icon,
  .clean-filter-options,
  .filter-box-text,
  .category-title,
  .select-all,
  .form-input,
  .error-message,
  .plan-tech,
  .plan-description,
  .form-action-button {
    font-size: 13.5px;
  }

  .filter-dot {
    height: 16.87px;
    width: 16.87px;
    font-size: 11.25px;
  }

  .category-box {
    width: 86.62px;
    height: 69.75px;
  }

  .category-icon {
    font-size: 24.75px;
  }

  .plan-characteristics-title {
    font-size: 12.37px;
  }

  .form-action-button {
    width: 94.5px;
    height: 38.25px;
  }
}

@media screen and (min-width: 1920px) {
  .form-title {
    font-size: 25.31px;
  }

  .close-form-icon,
  .form-section-title,
  .filter-options,
  .plan-name,
  .internet-characteristics,
  .sd-channels,
  .hd-channels,
  .telephone {
    font-size: 17.71px;
  }

  .form-input-name,
  .filter-title,
  .filter-icon,
  .clean-filter-options,
  .filter-box-text,
  .category-title,
  .select-all,
  .form-input,
  .error-message,
  .plan-tech,
  .plan-description,
  .form-action-button {
    font-size: 15.18px;
  }

  .filter-dot {
    height: 18.97px;
    width: 18.97px;
    font-size: 12.65px;
  }

  .category-box {
    width: 97.44px;
    height: 78.46px;
  }

  .category-icon {
    font-size: 27.84px;
  }

  .plan-characteristics-title {
    font-size: 13.91px;
  }

  .form-action-button {
    width: 106.31px;
    height: 43.03px;
  }
}