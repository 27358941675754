.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.align-center {
  align-items: center;
}

.subheader-title {
  color: #5a607f;
  font-size: 14px;
}

.hide-coverage-button {
  float: right;
  z-index: 1;
  margin-right: 10px;
  background-color: #1d428a;
  color: #ffffff;
  border-radius: 3px;
  height: 34px;
  font-size: 12px;
  border: none;
}

.hide-coverage-button:hover {
  background-color: #426da9;
  color: #ffffff !important;
  border: none;
}

.show-coverage-button {
  font-size: 14px;
  font-weight: bold;
  color: #2b80ff;
  padding: 0;
  outline: none;
  border: none;
  margin-bottom: 30px;
}

.pagination-container {
  margin: 15px 0;
}

.pagination {
  font-size: 12px;
  color: #001737;
}

.export-button {
  cursor: pointer;
  font-size: 14px;
  color: #2b80ff;
  font-weight: bold;

  .export-button-icon {
    margin: 0 10px 0 0;
  }
}

@media screen and (min-width: 1280px) {

  .subheader-title,
  .show-coverage-button,
  .export-button {
    font-size: 14px;
  }

  .hide-coverage-button,
  .pagination {
    font-size: 12px;
  }
}

@media screen and (min-width: 1366px) {

  .subheader-title,
  .show-coverage-button,
  .export-button {
    font-size: 15.75px;
  }

  .hide-coverage-button,
  .pagination {
    font-size: 13.5px;
  }
}

@media screen and (min-width: 1920px) {

  .subheader-title,
  .show-coverage-button,
  .export-button {
    font-size: 17.71px;
  }

  .hide-coverage-button,
  .pagination {
    font-size: 15.18px;
  }
}