.row {
  display: flex;
}

.column {
  display: flex;
  flex-flow: column;
}

.align-center {
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-title {
  color: #001737;
  font-size: 24px;
  font-weight: bold;
}

.header-breadcrumb {
  font-size: 12px;
  color: #001737;
}

.select {
  width: 150px;
  margin-right: 15px;
}

@media screen and (min-width: 1280px) {
  .header-title {
    font-size: 24px;
  }

  .header-breadcrumb {
    font-size: 12px;
  }

  .select {
    width: 150px;
  }
}

@media screen and (min-width: 1366px) {
  .header-title {
    font-size: 27px;
  }

  .header-breadcrumb {
    font-size: 13.5px;
  }

  .select {
    width: 168.75px;
  }
}

@media screen and (min-width: 1920px) {
  .header-title {
    font-size: 30.37px;
  }

  .header-breadcrumb {
    font-size: 15.18px;
  }

  .select {
    width: 189.84px;
  }
}